.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    z-index: 10;
  }
  
  .content {
    flex: 1; /* This allows the content to grow and push the footer down */
  }
